<template>
  <div>
    <v-card>
      <v-app-bar dark color="primary" flat dense>
        <v-app-bar-title class="white--text ml-3">{{Object.keys($route.params).length === 0 && !exist ? 'Nueva Tarea' : 'Editar Tarea' }}</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon color="white" >{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form
        ref="form"
      >
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="task.code"
                :loading="loadingCode"
                label="Código"
                @change="verifyCode"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="task.task_category_id"
                label="Categoría"
                @change="verifyCode"
                :items="selectCategoriesList"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    small
                    color="primary"
                    @click="showList($event, 'task_categories')"
                  >
                    <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
              </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="task.priority"
                label="Prioridad"
                :items="prioritys"
                @change="verifyCode"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-autocomplete
                v-model="task.project_id"
                label="Proyecto"
                @change="verifyCode"
                :items="selectProjectsList"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="task.project_status_id"
                label="Etapa"
                :items="selectStatusList"
                @change="verifyCode"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="task.description"
                label="Descripción"
                hide-details="auto"
                :rules="[validators.required]"
                :readonly="readonly"
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="task.estimated_time"
                @change="setTimes('estimated')"
                label="Tiempo Estimado"
                hide-details="auto"
                :rules="[validators.required, validators.isNumber]"
                :readonly="readonly"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-radio-group
                v-model="task.estimated_type"
                row
                @change="setTimes()"
                :rules="[validators.required]"
              >
                <v-radio
                  label="Días"
                  value="day"
                ></v-radio>
                <v-radio
                  label="Horas"
                  value="hour"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="start_date"
                    label="Fecha Inicio"
                    hide-details="auto"
                    :prepend-icon="icons.mdiCalendar"
                    readonly
                    v-on="on"
                    :rules="[validators.required]"
                  />
                </template>
                <v-date-picker
                  v-model="task.start_date"
                  @input="menu = false"
                  @change="setTimes('start')"
                  :first-day-of-week="1"
                  locale="es-ES"
                  :readonly="readonly"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="end_date"
                    label="Fecha Finalización"
                    :prepend-icon="icons.mdiCalendar"
                    hide-details="auto"
                    readonly
                    v-on="on"
                    :rules="[validators.required]"
                  />
                </template>
                <v-date-picker
                  v-model="task.end_date"
                  @input="menu2 = false"
                  :first-day-of-week="1"
                  locale="es-ES"
                  @change="setTimes('end')"
                  :readonly="readonly"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              class="px-2 mt-2"
            >
              <v-app-bar color="rgba(211, 36, 43, 0.4)" flat class="mb-3">
                <v-app-bar-title>Recurso a asignar</v-app-bar-title>
                <v-combobox
                  v-model="resource"
                  label="Añadir Recurso"
                  solo
                  hide-details
                  flat
                  class="mx-3"
                  :prepend-icon="icons.mdiMagnify"
                  :items="userList.filter(item => !resources.some(re => re.user_id === item.id) )"
                  :menu-props="{contentClass:'list-style'}"
                  @change="addResource"
                  ref="search_resource"
                >
                  <template #item="data">
                    <v-list-item-avatar
                      :color="data.item.avatar ? '' : 'primary'"
                      :class="data.item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    >
                      <v-img
                        v-if="data.item.avatar"
                        :src="require(`@/assets/images/avatars/${data.item.avatar}`)"
                      ></v-img>
                      <span
                        v-else
                        class="font-weight-medium"
                      >{{ avatarText(data.item.fullname) }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title >{{data.item.fullname}}</v-list-item-title>
                      <v-list-item-subtitle><small>{{data.item.email}}</small></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-app-bar>
            </v-col>

            <v-col
                cols="12"
                v-if="resources.length > 0"
              >
                <v-card flat>
                  <v-data-table
                    :headers="resourceColumns"
                    :items="resources"
                    class="elevation-1"
                  >
                    <template v-slot:top v-if="alert">
                      <v-alert
                        dense
                        type="error"
                      >
                        {{`La suma de la cantidad asignada de los recursos no puede superar a ${task.estimated_time}`}}
                      </v-alert>
                    </template>
                    <template #[`item.resource`]="{item}">
                      <v-text-field
                        v-model="item.resource"
                        outlined
                        class="pt-1 pb-1 input-table"
                        hide-details="auto"
                        readonly
                      />
                    </template>
                    <template #[`item.estimated_value`]="{item}">
                      <v-currency-field
                        v-model.number="item.estimated_value"
                        prefix="$"
                        outlined
                        hide-details="auto"
                        :rules="[validators.required]"
                        :readonly="readonly"
                        class="input-table"
                      ></v-currency-field>
                    </template>
                    <template #[`item.assigned_amount`]="{item}">
                      <v-text-field
                        v-model.number="item.assigned_amount"
                        outlined
                        class="pt-1 pb-1 input-table"
                        hide-details="auto"
                        :rules="[validators.required, validators.isNumber]"
                        :readonly="readonly"
                      />
                    </template>
                    <template #[`item.actions`]="{item}">
                      <div class="d-flex align-center justify-center">
                        <!-- delete -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              :disabled="readonly"
                              v-on="on"
                              @click="deleteResource(item)"
                            >
                              <v-icon size="18">
                                {{  icons.mdiDeleteOutline }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Borrar</span>
                        </v-tooltip>
                        <!-- Añadir -->
                        <!-- <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              :disabled="readonly"
                            >
                              <v-icon size="18">
                                {{ icons.mdiPlus }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Añadir nuevo</span>
                        </v-tooltip> -->
                      </div>
                    </template>
                  </v-data-table>
                </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="pa-5"
          >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="submit"
              :loading="loadingSubmit"
            >
              Guardar
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="reset"
            >
              Limpiar
            </v-btn>
            <v-btn
              color="secondary"
              class="mx-1"
              @click="close"
            >
              Retornar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <dialog-item-select
      v-model="dialog"
      v-if="dialog"
      :options="options"
    >
    </dialog-item-select>
    <v-menu
      v-model="showMenu"
      bottom
      right
      :position-x="x"
      :position-y="y"
    >
      <v-list>
        <v-list-item
          @click="newEditItem('new')"
        >
          <v-list-item-title>
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Nuevo Item</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="newEditItem('edit')">
          <v-list-item-title>
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon>
            <span>Editar Item</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="newEditItem('delete')">
          <v-list-item-title>
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
            <span>Borrar Item</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import apiAxios from '@/plugins/axios'
import VCurrencyField from '@/components/widgets/input/VCurrencyField.vue'
import DialogItemSelect from '@/components/widgets/dialog/DialogItemSelect.vue'
import {
  mdiClose,
  mdiCalendar,
  mdiMagnify,
  mdiDeleteOutline,
  mdiPlus,
  mdiDotsVertical,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, reactive, computed, watch, provide } from '@vue/composition-api'
import { isNumber, required } from '@core/utils/validation'
import useProjectsNew from '@/views/projects/projects-new/useProjectsNew'
import useProjectsList from '@/views/projects/projects-list/useProjectsList'
import useTasksNew from './useTasksNew'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { setLoading, validateForm } from '@/@core/utils'
import Vue from 'vue'
export default {
  components: { DialogItemSelect, VCurrencyField },
  setup() {
    const { router } = useRouter()
    const menu = ref(false)
    const showMenu = ref(false)
    const x = ref(null)
    const y = ref(null)
    const alert = ref(false)
    const menu2 = ref(false)
    const exist = ref(false)
    const dialog = ref(false)
    const loadingCode = ref(false)
    const loadingOne = ref(false)
    const loadingSubmit = ref(false)
    const readonly = ref(false)
    const resource = ref('')
    const resources = ref([])
    const project_resources = ref([])
    const project_physical_resources = ref([])
    const search_resource = ref(null)
    const form = ref(null)
    const task = reactive({
      code: '',
      task_category_id: '',
      priority: '',
      project_id: '',
      project_status_id: '',
      estimated_time: '',
      estimated_type: '',
      start_date: '',
      end_date: '',
      description: '',
      stated_at: true,
    })
    const options = reactive({
      title: '',
      selectList: '',
      create: true,
      delete: false,
      model: '',
      keyName: '',
    })
    const { fetchProjectStatus, selectStatusList } = useProjectsNew()
    const { fetchProjectList, selectProjectsList } = useProjectsList()
    const {
      create,
      createTask,
      configModal,
      fetchCategories,
      fetchUsers,
      fetchOne,
      fetchTaskResources,
      userList,
      resourceColumns,
      selectCategoriesList,
      setOne,
      task_resources,
      update,
      updateTask,
    } = useTasksNew()
    provide('createItem', create)
    provide('updateItem', update)
    provide('setOneItem', setOne)
    setLoading(true)

    Promise.all([
      fetchProjectStatus(),
      fetchProjectStatus(),
      fetchProjectList(),
      fetchUsers(),
      fetchCategories(),
      fetchTaskResources(),
    ])
      .then(() => {
        setLoading(false)
      })
      .catch(() => setLoading(false))

    watch(
      resources,
      val => {
        let value = 0
        resources.value.forEach(re => {
          value += re.assigned_amount
        })
        if (task.estimated_time && value > task.estimated_time) {
          alert.value = true
        } else alert.value = false
      },
      {
        deep: true,
      },
    )

    const prioritys = [
      {
        value: 'highest',
        text: 'Muy alta',
      },
      {
        value: 'high',
        text: 'Alta',
      },
      {
        value: 'normal',
        text: 'Normal',
      },
      {
        value: 'low',
        text: 'Baja',
      },
      {
        value: 'lowest',
        text: 'Muy baja',
      },
    ]

    const start_date = computed({
      get: () => {
        if (task.start_date === '') return ''
        return moment(task.start_date).format('DD-MMM-YYYY')
      },
      set: value => {
        return value
      },
    })
    const end_date = computed({
      get: () => {
        if (task.end_date === '') return ''
        return moment(task.end_date).format('DD-MMM-YYYY')
      },
      set: value => {
        return value
      },
    })

    if (Object.keys(router.currentRoute.params).length !== 0) {
      fetchOne(router.currentRoute.params.id)
        .then(res => {
          task.description = res.description
          task.code = res.code
          task.estimated_time = Number(res.estimated_time)
          task.estimated_type = res.estimated_type
          task.priority = res.priority
          task.start_date = res.start_date
          task.end_date = res.end_date
          task.task_category_id = res.task_category_id
          task.project_status_id = res.project_status.id
          task.project_id = res.project_id
          project_resources.value = res.task_resources.map(bud => {
            return {
              assigned_amount: Number(bud.assigned_amount),
              estimated_value: Number(bud.stimated_value),
              user_id: bud.user_id,
              resource: bud.resource,
            }
          })
          project_physical_resources.value = res.task_physical_resources.map(bud => {
            return {
              assigned_amount: Number(bud.assigned_amount),
              estimated_value: Number(bud.stimated_value),
              resource: bud.resource,
            }
          })
          resources.value = [...project_resources.value, ...project_physical_resources.value]
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }

    const addResource = item => {
      resource.value = ''
      search_resource.value.lazyValue = ''
      search_resource.value.lazySearch = ''
      const isFormValid = validateForm(form)
      if (!isFormValid || alert.value) return
      const finded = task_resources.value.find(res => res.user_id === item.id)
      const newResource = reactive({
        resource: item.fullname ? item.fullname : item,
        estimated_value: finded ? finded.stimated_value : 1,
        assigned_amount: '',
        user_id: item.id ? item.id : null,
      })
      if (resources.value.length === 0) {
        newResource.assigned_amount = Number(task.estimated_time)
      } else {
        let val = 0
        resources.value.forEach(re => {
          val += re.assigned_amount
        })
        newResource.assigned_amount = task.estimated_time - val
      }
      resources.value.push(newResource)
    }

    const close = () => {
      reset()
      router.push('/proyectos-tareas')
    }

    const reset = () => {
      form.value.reset()
      resources.value = []
      exist.value = false
      alert.value = false
      start_date.value = ''
      end_date.value = ''
    }

    const verifyCode = async () => {
      try {
        if (task.code && task.priority && task.task_category_id && task.project_id && task.project_status_id) {
          loadingCode.value = true
          const { data } = await apiAxios.get('/tasks', {
            params: {
              task: {
                code: task.code,
                task_category_id: task.task_category_id,
                priority: task.priority,
                project_id: task.project_id,
                project_status_id: task.project_status_id,
              },
            },
          })
          if (data.object.length !== 0) {
            exist.value = true
            const res = data.object[0]
            task.id = res.id
            task.description = res.description
            task.estimated_time = Number(res.estimated_time)
            task.estimated_type = res.estimated_type
            task.priority = res.priority
            task.start_date = res.start_date
            task.end_date = res.end_date
            task.task_category_id = res.task_category_id
            task.project_status_id = res.project_status.id
            task.project_id = res.project_id
            project_resources.value = res.task_resources.map(bud => {
              return {
                assigned_amount: bud.assigned_amount,
                estimated_value: Number(bud.stimated_value),
                user_id: bud.user_id,
                resource: bud.resource,
              }
            })
            project_physical_resources.value = res.task_physical_resources.map(bud => {
              return {
                assigned_amount: Number(bud.assigned_amount),
                estimated_value: Number(bud.stimated_value),
                resource: bud.resource,
              }
            })
            resources.value = [...project_resources.value, ...project_physical_resources.value]
          } else {
            exist.value = false
            task.description = ''
            task.estimated_time = ''
            task.estimated_type = ''
            task.start_date = ''
            task.end_date = ''
            project_resources.value = []
            project_physical_resources.value = []
            resources.value = []
          }
          loadingCode.value = false
        }
      } catch (error) {
        exist.value = false
        loadingCode.value = false
      }
    }

    const newEditItem = key => {
      if (key === 'new') {
        options.create = true
        options.delete = false
      } else {
        options.create = false
        options.delete = false
      }
      if (key === 'delete') {
        options.delete = true
        options.create = false
      }
      if (options.selectList2) {
        dialog2.value = true
      } else dialog.value = true
    }

    const setTimes = val => {
      if (val === 'start') {
        if (task.estimated_time && task.estimated_type === 'day') {
          task.end_date = moment(task.start_date).add(Number(task.estimated_time), 'days').format('YYYY-MM-DD')
        }
      } else if (val === 'end') {
        if (task.start_date) {
          task.estimated_time = moment(task.end_date).diff(moment(task.start_date), 'days')
          task.estimated_type = 'day'
        }
      } else if (val === 'estimated') {
        if (task.start_date && task.estimated_type === 'day') {
          task.end_date = moment(task.start_date).add(Number(task.estimated_time), 'days').format('YYYY-MM-DD')
        }
      } else {
        if (task.start_date && task.estimated_type === 'hour' && task.end_date) {
          task.end_date = ''
          task.start_date = ''
        }
      }
    }

    const showList = (e, key) => {
      e.preventDefault()
      x.value = e.clientX
      y.value = e.clientY
      Vue.nextTick(() => {
        showMenu.value = true
        options.title = configModal(key).title
        options.selectList = configModal(key).select_list
        options.model = configModal(key).model
        options.keyName = configModal(key).keyName
      })
    }

    const submit = async () => {
      try {
        const isFormValid = validateForm(form)
        if (!isFormValid) return
        loadingSubmit.value = true
        let task_resources_attributes = []
        let task_physical_resources_attributes = []
        if (resources.value) {
          resources.value.forEach(re => {
            let ele = {}
            if (re.user_id) {
              ele = {
                user_id: re.user_id,
                stimated_value: re.estimated_value,
                assigned_amount: re.assigned_amount,
              }
              if (re.id) ele.id = re.id
              task_resources_attributes.push(ele)
            } else {
              ele = {
                resource: re.resource,
                stimated_value: re.estimated_value,
                assigned_amount: re.assigned_amount,
              }
              if (re.id) ele.id = re.id
              task_physical_resources_attributes.push(ele)
            }
          })
        }
        task['task_resources_attributes'] = task_resources_attributes
        task['task_physical_resources_attributes'] = task_physical_resources_attributes
        task['task_trackings_attributes'] = []
        console.log(task)
        if (Object.keys(router.currentRoute.params).length === 0 && !exist.value) {
          await createTask(task)
          loadingSubmit.value = false
          close()
        } else {
          if (!task.id) task.id = router.currentRoute.params.id
          const data = await updateTask(task)
          loadingSubmit.value = false
          close()
        }
      } catch (error) {
        loadingSubmit.value = false
      }
    }

    const deleteResource = item => {
      let index = resources.value.indexOf(item)
      resources.value.splice(index, 1)
    }

    return {
      addResource,
      alert,
      avatarText,
      showList,
      close,
      deleteResource,
      dialog,
      end_date,
      exist,
      form,
      loadingCode,
      loadingOne,
      loadingSubmit,
      menu,
      menu2,
      newEditItem,
      options,
      prioritys,
      readonly,
      reset,
      resource,
      resources,
      resourceColumns,
      search_resource,
      selectCategoriesList,
      selectProjectsList,
      selectStatusList,
      setTimes,
      showMenu,
      start_date,
      submit,
      task,
      userList,
      verifyCode,
      x,
      y,
      icons: {
        mdiCalendar,
        mdiClose,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiMagnify,
        mdiPencilOutline,
        mdiPlus,
      },
      validators: {
        isNumber,
        required,
      },
    }
  },
}
</script>
