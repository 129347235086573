import store from '@/store'
import { ref, watch, computed } from '@vue/composition-api'

export default function useProjectsNew() {

  const budgetSourceTypesList = ref([])
  const budgetTypesList = ref([])
  const geographiesList = ref([])
  const linesList = ref([])
  const categoriesList = ref([])
  const originsList = ref([])
  const populationsList = ref([])
  const sectorsList = ref([])
  const subSectorsList = ref([])
  const statusList = ref([])
  const task_resources = ref([])
  const thirdPartiesList = ref([])
  const typeList = ref([])
  const umList = ref([])
  const userList = ref([])
  const zonesList = ref([])
  const loading = ref(false)

  const resourceColumns = [
    { text: 'RECURSO', value: 'resource', sortable: false},
    { text: 'VALOR ESTIMADO', value: 'estimated_value', sortable: false },
    { text: 'CANTIDAD ASIGNADA', value: 'assigned_amount' },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const fetchUsers = () => {
    loading.value = true
    store
      .dispatch('users/fetchUsers')
      .then(response => {
        const data = ref([])
        if (response) data.value = response.map(ele => {
          return JSON.parse(ele['listusers']).users
        })

        userList.value = data.value[0].map(user => {
          return {
            ...user,
            value: user,
          }
        })

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }

  //create
  const create = async payload => {
    try {
      const response = await store.dispatch('tasks/create', payload)
      const call = configModal(payload.model).function
      await call()
      return response
    } catch (error) {

    }
  }

  const createTask = async payload => {
    try {
      const response = await store.dispatch('tasks/createTask', payload)
      return response
    } catch (error) {

    }
  }

  const createProjectFormulations = async payload => {
    try {
      const response = await store.dispatch('projects/createProjectFormulations', payload)
      return response
    } catch (error) {

    }
  }

  const updateTask = async payload => {
    try {
      const response = await store.dispatch('tasks/updateTask', payload)
      return response
    } catch (error) {

    }
  }
  const updateProjectFormulations = async payload => {
    try {
      const response = await store.dispatch('projects/updateProjectFormulations', payload)
      return response
    } catch (error) {

    }
  }

  const setOne = async payload => {
    try {
      const response = await store.dispatch('tasks/setOne', payload)
      return response.object
    } catch (error) {

    }
  }

  //update
  const update = async payload => {
    try {
      const response = await store.dispatch('tasks/update', payload)
      const call = configModal(payload.model).function
      await call()
      return response
    } catch (error) {

    }
  }

  // fetch data
  const fetchCategories = async () => {
    const response = await store.dispatch('tasks/fetchList', 'task_categories')
    categoriesList.value = response
  }
  const fetchGeographies = async () => {
    const response = await store.dispatch('projects/fetchList', 'geographies')
      geographiesList.value = response.object
  }
  const fetchLines = async () => {
    const response = await store.dispatch('projects/fetchList', 'lines')
      linesList.value = response.object
  }
  const fetchOne = async (id) => {
    try {
      const res = await store
      .dispatch('tasks/fetchOne', id)
      return res.object
    } catch (error) {
    }
  }
  const fetchOneFormulations = async (id) => {
    try {
      const res = await store
      .dispatch('projects/fetchOneFormulations', id)
      return res.object
    } catch (error) {
    }
  }
  const fetchProjectBudgets = () => {
    store.dispatch('projects/fetchList', 'project_budgets')
    .then(response => {

    })
  }
  const fetchProjectStatus = async () => {
    const response = await store.dispatch('projects/fetchList', 'project_statuses')
      statusList.value = response.object
  }
  const fetchBudgetSourceTypes = async () => {
    const response = await store.dispatch('projects/fetchList', 'budget_source_types')
      budgetSourceTypesList.value = response.object
  }
  const fetchBudgetTypes = async () => {
    const response = await store.dispatch('projects/fetchList', 'budget_types')
      budgetTypesList.value = response.object
  }
  const fetchProjectTypes = async () => {
    const response = await store.dispatch('projects/fetchList', 'project_types')
      typeList.value = response.object
  }
  const fetchOrigins = async () => {
    const response = await store.dispatch('projects/fetchList', 'origins')
      originsList.value = response.object
  }
  const fetchPopulations = async () => {
    const response = await store.dispatch('projects/fetchList', 'populations')
      populationsList.value = response.object
  }
  const fetchSectors = async () => {
    const response = await store.dispatch('projects/fetchList', 'sectors')
      sectorsList.value = response.object
  }
  const fetchSubSectors = async () => {
    const response = await store.dispatch('projects/fetchList', 'subsectors')
      subSectorsList.value = response.object
  }
  const fetchThirdParties = async () => {
    const response = await store.dispatch('projects/fetchList', 'third_parties')
      thirdPartiesList.value = response.object
  }
  const fetchUM = async () => {
    const response = await store.dispatch('projects/fetchList', 'unit_measurements')
      umList.value = response
  }
  const fetchZones = async () => {
    const response = await store.dispatch('projects/fetchList', 'zones')
      zonesList.value = response.object
  }

  const fetchTaskResources = async () => {
    const response = await store.dispatch('tasks/fetchTaskResources')
    task_resources.value = response.task_resources
  }

  const selectList = (array) => {
    const list = ref([])
    if (array.value) {
      list.value = array.value.filter(item => item.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
          code: type.code
        }
        return ele
      })
    }
    return list.value
  }

  const selectBudgetsList = array => {
    const list = ref([])
    if (array.value) {
      list.value = array.value.filter(item => item.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
        }
        return ele
      })
    }
    return list.value
  }

  const selectGeographiesList = computed(() => {
    const list = ref([])
    if (geographiesList.value) {
      list.value = geographiesList.value
      .map(type => {
        const ele = {
          value: type.id,
          text: type.ancestries ? type.ancestries + ', ' + type.description :  type.description,
        }
        return ele
      })
    }
    return list.value
  })
  const selectBudgetSourceTypesList = computed(() => {
    return selectBudgetsList(budgetSourceTypesList)
  })
  const selectBudgetTypesList = computed(() => {
    return selectBudgetsList(budgetTypesList)
  })
  const selectCategoriesList = computed(() => {
    const list = ref([])
    if (categoriesList.value) {
      list.value = categoriesList.value.filter(item => item.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
        }
        return ele
      })
    }
    return list.value
  })
  const selectLinesList = computed(() => {
    const list = ref([])
    if (linesList.value) {
      linesList.value
      .forEach(type => {
        type.lines.forEach(line => {
          const ele = {
            line_category_id: line.line_category_id,
            value: line.id,
            text: line.name_of,
            code: line.code
          }
          list.value.push(ele)
        })
      })
    }
    return list.value
  })
  const selectLinesCategoriesList = computed(() => {
    const list = ref([])
    if (linesList.value) {
      linesList.value
      .forEach((type, i) => {
        if (i !== 0) {
          list.value.push({ divider: true })
        }
        const header = { header: type.line_category_name_of }
        list.value.push(header)
        type.lines.forEach(line => {
          if (line.stated_at){
            const ele = {
              id_category: line.line_category_id,
              value: line.id,
              title_category: line.name_of,
              active: false,
              stated_at: line.stated_at
            }
            list.value.push(ele)
          }
        })

      })
    }
    return list.value
  })
  const selectStatusList = computed(() => {
    const list = ref([])
    if (statusList.value) {
      list.value = statusList.value.filter(item => item.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
          type: type.type_project_status
        }
        return ele
      })
    }
    return list.value
  })
  const selectOriginsList = computed(() => {
    return selectList(originsList)
  })
  const selectPopulationsList = computed(() => {
    return selectList(populationsList)
  })
  const selectSectorsList = computed(() => {
    return selectList(sectorsList)
  })
  const selectSubSectorsList = computed(() => {
    return selectList(subSectorsList)
  })
  const selectThirdPartiesList = computed(() => {
    const list = ref([])
    if (thirdPartiesList.value) {
      list.value = thirdPartiesList.value.filter( third => third.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.full_name,
          identification: type.identification,
          full_name: type.full_name,
        }
        return ele
      })
    }
    return list.value
  })
  const selectUMList = computed(() => {
    const list = ref([])
    if (umList.value) {
      list.value = umList.value.filter( um => um.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of
        }
        return ele
      })
    }
    return list.value
  })
  const selectTypeList = computed(() => {
    return selectList(typeList)
  })
  const selectZonesList = computed(() => {
    return selectList(zonesList)
  })

  const configModal = key => {
    if (key === 'task_categories')
    return {
      title: 'Categorías',
      select_list: selectCategoriesList,
      model: 'task_categories',
      keyName: 'task_category',
      function: fetchCategories
    }
  }


  return {
    resourceColumns,
    create,
    createTask,
    createProjectFormulations,
    configModal,
    fetchCategories,
    fetchBudgetSourceTypes,
    fetchBudgetTypes,
    fetchGeographies,
    fetchLines,
    fetchOne,
    fetchOneFormulations,
    fetchOrigins,
    fetchProjectBudgets,
    fetchProjectStatus,
    fetchProjectTypes,
    fetchPopulations,
    fetchSectors,
    fetchSubSectors,
    fetchTaskResources,
    fetchThirdParties,
    fetchUM,
    fetchZones,
    fetchUsers,
    loading,
    selectBudgetSourceTypesList,
    selectBudgetTypesList,
    selectCategoriesList,
    selectGeographiesList,
    selectLinesList,
    selectLinesCategoriesList,
    selectOriginsList,
    selectStatusList,
    selectPopulationsList,
    selectSectorsList,
    selectSubSectorsList,
    selectThirdPartiesList,
    selectTypeList,
    selectUMList,
    selectZonesList,
    setOne,
    task_resources,
    update,
    updateTask,
    updateProjectFormulations,
    userList
  }
}
